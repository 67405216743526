import React, { useState, useEffect, useRef } from "react"
import { Modal, Button, Input, Form, message, Checkbox } from "antd"
import GetCodeBtn from './getCode'
const Login = (props) => {
    const [state, setState] = useState(1)
    const [phone,setPhone] = useState()
    const getCodeBtnRef = useRef()

    const tableClick = (val) => {
        props.stateChange(val)
        setState(val)
    }
    return (
        <div>
            <div className="body_flex login_way">
                <div className={`login_head ${state == 1 ? 'login_active' : ''}`} onClick={() => { tableClick(1) }}>密码登录</div>
                <div className={`login_head ${state == 2 ? 'login_active' : ''}`} onClick={() => { tableClick(2) }}>验证码登录</div>
            </div>
            <div>
                {state == 1 && <div>
                    <Form.Item
                        label="邮箱/手机号："
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: '请输入邮箱/手机号！',
                            },
                        ]}
                    >
                        <Input placeholder="请输入您的邮箱/手机号" />
                    </Form.Item>
                    <Form.Item
                        label="密码："
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: '请输入密码！',
                            },
                        ]}
                    >
                        <Input.Password placeholder="请输入密码" />
                    </Form.Item>
                </div>}
                {
                    state == 2 && <div>
                        <Form.Item
                            label="手机号："
                            name="phone"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入手机号！',
                                },
                            ]}
                        >
                            <Input placeholder="请输入手机号" onChange={(e)=>{setPhone(e.target.value)}} />
                        </Form.Item>
                        <Form.Item label='验证码：' className="login_code_item">
                            <div className="body_flex">
                                <Form.Item
                                    noStyle
                                    name="code"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入验证码！',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入手机号验证码" />
                                </Form.Item>
                                <GetCodeBtn ref={getCodeBtnRef} phone={phone} message='请输入手机号' type={6} />
                            </div>
                        </Form.Item>
                    </div>
                }
                <div className="login_forget_pas">
                    <a >忘记密码?</a>
                </div>
                <Button type='primary' className="login_btn" onClick={()=>{props.handleOk()}}>登录</Button>
                <div className="login_register_text"><a onClick={() => { props.typeChange(2) }}>还没有账号？立即注册</a></div>
            </div>
        </div>
    )
}
export default Login
