import React from "react"
// import { useHistory, useLocation } from "react-router-dom"
import { useDispatch } from 'react-redux';
const News = () => {
    return (
        <div>
            {alert(2)}
            觉得亟待解决的亟待解决的家
        </div>
    )
}
export default News
