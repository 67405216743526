import request from "../script/request";

export function getCodeByMobile(data) {
  return request({
    url: "/intel_gba/api/randomcodes/getCodeByMobile",
    method: "post",
    data,
  });
}

export function getCodeByEmail(data) {
  return request({
    url: "/intel_gba/api/randomcodes/getCodeByEmail",
    method: "post",
    data,
  });
}

export function sendEmailCode(data) {
  return request({
    url: "/intel_gba/api/tempusers/sendEmailCode",
    method: "post",
    data,
  });
}

export function resetPassword(data) {
  return request({
    url: "/intel_gba/api/tempusers/resetPassword",
    method: "post",
    data,
  });
}
export function updateUserInfo(data) {
  return request({
    url: "/intel_gba/api/tempusers/updateUserInfo",
    method: "post",
    data,
  });
}

export function loginByUsernameAndPass(data) {
  return request({
    url: "/intel_gba/api/tempusers/loginByUsernameAndPass",
    method: "post",
    data,
  });
}

export function loginByEmail(data) {
  return request({
    url: "/intel_gba/api/tempusers/loginByEmail",
    method: "post",
    data,
  });
}
export function loginOut(data) {
  return request({
    url: '/intel_gba/api/tempusers/logout',
    method: 'post',
    data
  })
}
export function getCoursetypeList(data) {
  return request({
    url: '/intel_gba/api/learn_answers/getCoursetypeList',
    method: 'post',
    data
  })
}
export function getCourseunitList(data) {
  return request({
    url: '/intel_gba/api/learn_answers/getCourseunitList',
    method: 'post',
    data
  })
}
export function getLearnTagList(data) {
  return request({
    url: '/intel_gba/api/learn_tags/getLearnTagList',
    method: 'post',
    data
  })
}
export function getCourseunitByID(data) {
  return request({
    url: '/intel_gba/api/learn_answers/getCourseunitByID',
    method: 'post',
    data
  })
}

export function CreateOperator(data) {
  return request({
    url: '/intel_gba/api/learn_answers/CreateOperator',
    method: 'post',
    data
  })
}

export function UpdateOperator(data) {
  return request({
    url: '/intel_gba/api/learn_answers/UpdateOperator',
    method: 'post',
    data
  })
}

export function Findavwatermark(data) {
  return request({
    url: '/intel_gba/api/learn_answers/Findavwatermark',
    method: 'post',
    data
  })
}

export function Filesavwatermark(data) {
  return request({
    url: '/intel_gba/api/learn_answers/Filesavwatermark',
    method: 'post',
    data
  })
}

export function SubmitExamina(data) {
  return request({
    url: '/intel_gba/api/learn_answers/SubmitExamina',
    method: 'post',
    data
  })
}

export function FindExamina(data) {
  return request({
    url: '/intel_gba/api/learn_answers/FindExamina',
    method: 'post',
    data
  })
}

export function uploadCertificate(data) {
  return request({
    url: '/intel_gba/api/files/upload',
    method: 'post',
    data
  })
}
export function UpdateExaminaCert(data) {
  return request({
    url: '/intel_gba/api/learn_answers/UpdateExaminaCert',
    method: 'post',
    data
  })
}

export function FindExaminaById(data) {
  return request({
    url: '/intel_gba/api/learn_answers/FindExaminaById',
    method: 'post',
    data
  })
}

export function getClassifyList(data) {
  return request({
    url: '/intel_gba/api/banners/getClassifyList',
    method: 'post',
    data
  })
}

export function FindCoursetype(data) {
  return request({
    url: '/intel_gba/api/learn_answers/FindCoursetype',
    method: 'post',
    data
  })
}

export function get_login_url(data) {
  return request({
    url: '/intel_gba/api/tempusers/get_login_url',
    method: 'post',
    data
  })
}

export function rpc_login(data) {
  return request({
    url: '/intel_gba/api/tempusers/rpc_login',
    method: 'post',
    data
  })
}
export function FindUserByID(data) {
  return request({
    url: '/intel_gba/api/learn_answers/FindUserByID',
    method: 'post',
    data
  })
}