/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { useDispatch } from 'react-redux';
import { Player, BigPlayButton, PlaybackRateMenuButton, ControlBar } from "video-react";
import "video-react/dist/video-react.css";
import '../css/oem.css'
import { Statistic, Spin, Tooltip, message , Modal} from 'antd'
import { getCourseunitByID, CreateOperator, UpdateOperator, Findavwatermark, Filesavwatermark, get_login_url, rpc_login, FindUserByID } from '../api'
import parse from "html-react-parser";
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { Countdown } = Statistic
const { confirm } = Modal;
const CourseDetails = () => {
    const player = useRef()
    const dispatch = useDispatch()
    const location = useLocation()
    const params = location.state
    const history = useHistory()
    const [unitDetails, setUnitDetails] = useState({})
    const [recommend, setRecommend] = useState([])
    const [time, setTime] = useState()
    const [duration, setDuration] = useState()
    const [show, setShow] = useState(false)
    const [testBtn,setTestBtn] = useState(false)
    const [loading, setLoading] = useState(false)
    const [top, setTop] = useState(0)
    const [videoUrl, setVideoUrl] = useState()
    const [otherIndex, setOtherIndex] = useState(3)
    const [id, setId] = useState()
    const [recordId, setRecordId] = useState()
    const [recordVideoTime, setrecordVideoTime] = useState()
    const [numDetails, setNumDetails] = useState({})
    const [files, setFiles] = useState([])
    const [showPractice,setShowPractice] = useState(false)
    const timer = useRef()
    const videoTimer = useRef()
    const watermarkTimer = useRef()
    const watermarkTimer1 = useRef()
    useEffect(() => {
        window.scroll(0, 0)
        const { id, ticket } = getQueryString()
        let flag = IsPC();
        if (!flag) {
            //手机端
            let url = window.location.href
            if (url.indexOf('icourse') !== -1) {
                window.open(`https://icourse.igbacenter.com/gba.m/coursedetails?id=${id}`, "_self");
            } else {
                window.open(`https://cce.zqnetlink.cn/gba.m/coursedetails?id=${id}`, "_self");
            }

        } else {
            let token = localStorage.getItem('gbaToken')
            if (ticket) {
                rpc_login({ ticket }).then(res => {
                    if (res.data.code === 200) {
                        let data = res.data.data
                        dispatch({
                            type: 'user_name',
                            data: data.data.name
                        })
                        let obj = {}
                        obj.id = data.data.id
                        obj.name = data.data.name
                        localStorage.setItem('gbaToken', data.token)
                        localStorage.setItem('gbaNews', JSON.stringify(obj))
                        recordTime(id, 5)
                        getUnitDetails(id)
                        getActionNum(id)
                        history.replace('/gba/coursedetails?id=' + id)
                    }
                })
            } else {
                if (token && id) {
                    recordTime(id, 5)
                    getUnitDetails(id)
                    getActionNum(id)
                } else {
                    // history.replace('/gba/')
                    if (!token) {
                        let url1 = window.location.href, url;
                        if (url1.indexOf('icourse') !== -1) {
                            url = `https://icourse.igbacenter.com/gba/coursedetails?id=${id}`;
                        } else if (url1.indexOf('zqnetlink') !== -1) {
                            url = `https://cce.zqnetlink.cn/gba/coursedetails?id=${id}`;
                        }
                        get_login_url({ type: 1, redirect_url: url }).then(res => {
                            if (res.data.msg === 'ok') {
                                window.open(res.data.data.url, '_self')
                            }
                        })
                    }
                }
            }
        }

        document.oncontextmenu = function () { //禁用右键菜单
            return false;
        }
        return () => {
            clearInterval(timer.current)
            clearInterval(videoTimer.current)
            clearInterval(watermarkTimer.current)
            clearInterval(watermarkTimer1.current)
        }
    }, [])

    const IsPC = () => {
        //判断是pc端还是手机端
        let userAgentInfo = navigator.userAgent;
        let Agents = [
            "Android",
            "iPhone",
            "SymbianOS",
            "Windows Phone",
            "iPad",
            "iPod",
        ];
        let flag = true;
        for (let v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                flag = false;
                break;
            }
        }
        return flag;
    };

    const getQueryString = (url = window.location.href) => {
        var parse = url.substring(url.indexOf("?") + 1),
            params = parse.split("&"),
            len = params.length,
            item = [],
            param = {}

        for (var i = 0; i < len; i++) {
            item = params[i].split("=")
            param[item[0]] = item[1]
        }

        return param
    }

    const getUnitDetails = (id) => {
        let user = JSON.parse(localStorage.getItem('gbaNews'))
        setLoading(true)
        getCourseunitByID({ id: id, uid: user.id }).then(res => {
            if (res.data.statusCode === 200) {
                let data = res.data.data
                let timeData = res.data.timeData
                setId(id)
                if (data.type === 3) {
                    if (data.video) {
                        setShowPractice(true)
                    } else {
                        setShowPractice(false)
                    }
                }
                if (data.watermark === 3) {
                    //后台选择没有水印的时候
                    setLoading(false)
                    //防止在调用该接口时视频重新播放
                    if (data.type === 1 || data.type === 3) {
                        if (data.video) {
                            let video = JSON.parse(data.video)
                            setVideoUrl(video[0].url)
                        } else {
                            setVideoUrl('')
                        }
                    }
                } else {
                    Findavwatermark({ id, watermark: data.watermark === 1 ? user.name : 'intel提供', type: 1 }).then(res => {
                        if (res.data.data) {
                            if (data.type === 1) {
                                // 1视频 2pdf
                                setTimeout(() => {
                                    player.current.load()
                                }, 100);
                                setVideoUrl(res.data.data.url)
                                setLoading(false)
                            }
                        } else {
                            // 没有加水印的地址去轮询查找
                            watermarkTimer.current = setInterval(() => {
                                getWatermarkUrl(1, id, data)
                            }, 15000);
                        }
                    })
                    // getWatermarkUrl(1, id, data)
                }

                if (data.type === 2) {
                    setShow(true)
                }

                if (data.files) {
                    if (data.watermark === 3) {
                        setFiles(JSON.parse(data.files))
                    } else {
                        Filesavwatermark({ id, watermark: data.watermark === 1 ? user.name : 'intel提供', type: 2 }).then(res => {
                            if (res.data.data) {
                                setFiles(res.data.data)
                            } else {
                                // 没有加水印的地址去轮询查找
                                watermarkTimer1.current = setInterval(() => {
                                    getFilesWatermarkUrl(2, id, data)
                                }, 15000);
                            }
                        })
                    }
                    data.files = JSON.parse(data.files)
                }
                setRecommend(res.data.tuijian)


                if (data.score_type) {
                    setTime('')
                    setShow(false)
                    setTestBtn(false)
                    setDuration('')
                } else {
                    if (data.use_exam > 0) {
                        if (!timeData.duration || timeData.duration === 0 || (timeData.page_remain / 60) >= timeData.duration) {
                            setTime('')
                            setShow(false)
                            setTestBtn(false)
                            setDuration('')
                        } else {
                            let time = timeData.duration - ((timeData.page_remain || 0) / 60)
                            setDuration(timeData.duration * 60)
                            const deadline = Date.now() + time * 60 * 1000
                            setTime(deadline)
                            setTestBtn(true)
                        }
                    }
                }

                setUnitDetails(data)
            }
        })
    }

    const getFilesWatermarkUrl = (type, id, data) => {
        let user = JSON.parse(localStorage.getItem('gbaNews'))
        Filesavwatermark({ id, watermark: data.watermark === 1 ? user.name : 'intel提供', type }).then(res => {
            if (res.data.data) {
                clearInterval(watermarkTimer1)
                setFiles(res.data.data)
            }
        })
    }

    const getWatermarkUrl = (type, id, data) => {
        let user = JSON.parse(localStorage.getItem('gbaNews'))
        Findavwatermark({ id, watermark: data.watermark === 1 ? user.name : 'intel提供', type }).then(res => {
            if (res.data.data) {
                if (type === 1) {
                    //视频/pdf加水印完成type=1
                    clearInterval(watermarkTimer)
                    if (data.type === 1) {
                        //视频视频完成
                        setVideoUrl(res.data.data.url)
                        setLoading(false)
                    }
                }
            }
        })
    }

    const recordClick = (val) => {
        if (val === 2) {
            console.log(location, 'location')
            let url = window.location.href
            let copyInput = document.createElement('input');//创建input元素
            document.body.appendChild(copyInput);//向页面底部追加输入框
            copyInput.setAttribute('value', url);//添加属性，将url赋值给input元素的value属性
            copyInput.select();//选择input元素
            document.execCommand("Copy");//执行复制命令
            message.success('复制链接成功！')
            copyInput.remove();
        }
        recordTime(id, val)
    }

    const getActionNum = (id) => {
        let user = JSON.parse(localStorage.getItem('gbaNews'))
        getCourseunitByID({ id: id, uid: user.id }).then(res => {
            setNumDetails(res.data.data)
        })

    }

    const testClick = () => {
        // history.push('/gba/test', { id: id })
        if (!time) {
            let user = JSON.parse(localStorage.getItem('gbaNews'))
            FindUserByID({ uid: user.id }).then(res => {
                if (res.data.statusCode === 200) {
                    if (res.data.data.full_name) {
                        let obj = {}
                        obj.id = user.id
                        obj.name = res.data.data.full_name
                        localStorage.setItem('gbaNews', JSON.stringify(obj))
                        history.push('/gba/test', { id: id })
                        dispatch({
                            type: 'user_name',
                            data: res.data.data.full_name
                        })
                    } else {
                        confirm({
                            title: '温馨提示',
                            icon: <ExclamationCircleOutlined />,
                            content: '请先完善个人资料里面的真实姓名，没有真实姓名不能考试！',
                            onOk() {
                              window.open('https://www.igbacenter.com/my/index.html')
                            },
                            onCancel() {
                              console.log('Cancel');
                            },
                          });
                    }
                }
            })
        }
    }

    const videoTime = () => {
        //记录浏览视频时间
        let video = document.getElementById('video-1')
        video.addEventListener('play', () => {
            if (videoTimer.current) {
                clearInterval(videoTimer.current)
            }
            setShow(true)
            if (recordId) {
                setrecordVideoTime(Date.now())
                videoTimer.current = setInterval(() => {
                    UpdateOperator({ id: recordId, video_remain: 30 })
                    setrecordVideoTime(Date.now())
                }, 30000);
            }
        })
        video.addEventListener('pause', () => {
            if (videoTimer.current) {
                clearInterval(videoTimer.current)
            }
            let videoTime = recordVideoTime
            if (videoTime) {
                let now = Date.now()
                let times = Math.floor((now - videoTime) / 1000)
                if (videoTime.currentr) {
                    clearInterval(videoTime.currentr)
                }
                // if (times) {
                //     UpdateOperator({ id: recordId, video_remain: times })
                // }
                setrecordVideoTime(null)
            }
        })
    }

    useEffect(() => {
        if (videoUrl) {
            player.current.load()
            videoTime()
        }
    }, [videoUrl])

    const pdfClick = (val) => {
        if (files.length > 0) {
            files.map(item => {
                if (item.name === val.name) {
                    if (unitDetails.watermark === 3) {
                        //无水印的地址
                        window.open(item.url)
                    } else {
                        //有水印的地址
                        if (item.nowurl) {
                            window.open(item.nowurl)
                        } else {
                            message.warning('您好，首次加载需要进行加密处理，请耐心等待3-5分钟左右，谢谢您的理解！')
                        }
                    }

                }
            })
        } else {
            message.warning('您好，首次加载需要进行加密处理，请耐心等待3-5分钟左右，谢谢您的理解！')
        }
    }

    const recordTime = (id, type) => {
        // type ( 1 点赞,2 转发,3 收藏 5浏览)
        let user = JSON.parse(localStorage.getItem('gbaNews'))
        CreateOperator({ tid: id, uid: user.id, type }).then(res => {
            setRecordId(res.data.maidian)
            if (type === 5) {
                //记录页面浏览时间
                timer.current = setInterval(() => {
                    UpdateOperator({ id: res.data.maidian, page_remain: 30 })
                }, 30000);
            }
            getActionNum(id)
        })
    }


    const recommendClick = (val) => {
        clearInterval(timer.current)
        clearInterval(videoTimer.current)
        clearInterval(watermarkTimer.current)
        clearInterval(watermarkTimer1.current)
        setTime('')
        setVideoUrl('')
        setShow(false)
        getUnitDetails(val.id)
        recordTime(val.id,5)
    }

    const imgClick = (val) => {
        let index = otherIndex
        let topl = top
        if (val === 1 && index > 3) {
            index--
            topl = topl + 176
        } else if (val === 2 && index < recommend.length) {
            index++
            topl = topl - 176
        }
        setOtherIndex(index)
        setTop(topl)
    }

    const timeFinish = () => {
        setTime('');
        setShow(false);
        setTestBtn(false)
        UpdateOperator({ id: recordId, video_remain:duration }).then(() => {
            setDuration('')
        })
    }

    const unitClick = (unitDetails) => {
        if (unitDetails.type === 2) {
            window.open(unitDetails.url)
        }
    }

    return (
        <div className="details_body">
            <div>当前位置：<span style={{ cursor: 'pointer' }} onClick={() => { history.push('/gba') }}>{unitDetails.pname}</span>&gt;<span style={{ color: '#0171C5' }}>{unitDetails.title}</span></div>
            <div className="body_flex detalis_cont">
                <div className="details_left">
                    <div className="details_title">{unitDetails.title}</div>
                    <div className="body_flex" style={{ justifyContent: 'space-between', margin: '14px 0' }}>
                        <div className="body_flex">
                            {numDetails.liulan_num > 500 &&<div className="details_num body_flex">
                                <img src={require('../img/details5.png')} alt="" />

                                <span>{numDetails.liulan_num || 0}</span>
                            </div>}
                            <div className="details_num body_flex" onClick={() => { recordClick(1) }} style={{ cursor: 'pointer' }}>
                                <img src={require(`../img/${numDetails.zan ? 'details1' : 'details10'}.png`)} alt="" style={{ width: 12, height: 11 }} />
                                {numDetails.zan_num >500 && <span>{numDetails.zan_num || 0}</span>}
                            </div>
                            <div className="details_num body_flex" onClick={() => { recordClick(2) }} style={{ cursor: 'pointer' }}>
                                <img src={require('../img/details2.png')} alt="转发" style={{ width: 11, height: 11 }} />
                                {/* <span>{numDetails.zhuanfa_num || 0}</span> */}
                            </div>
                            <div className="details_num body_flex" onClick={() => { recordClick(3) }} style={{ cursor: 'pointer' }}>
                                <img src={require(`../img/${numDetails.collect ? 'details8' : 'details9'}.png`)} alt="收藏" style={{ width: 12, height: 11 }} />
                                {/* <span>{numDetails.collect_num || 0}</span> */}
                            </div>
                        </div>
                        {unitDetails.type !== 3 ? <div className='body_flex' style={{ height: 35 }}>
                            {unitDetails.score_type === 1 && unitDetails.score && <div className='oem_details_test_score'>Pass:{unitDetails.score}</div>}
                            {unitDetails.score_type === 2 && <div className='oem_details_test_score oem_details_test_fail'>Fail:{unitDetails.score}</div>}
                            {time && show && <div className='body_flex oem_details_time' style={{ marginRight: 20 }}>
                                <span>请先认真学习，预计</span>
                                <Countdown value={time} onFinish={timeFinish} />
                                <span>后可开始考试</span>
                            </div>}
                            {unitDetails.use_exam > 0 && <div className='oem_details_test_btn' onClick={testClick} style={{ background: testBtn ? '#f5f5f5' : '#0071C4', color: testBtn ? '#333' : '#fff' }}>{unitDetails.score_type ? '再次考试' : '开始考试'}</div>}
                        </div> : <div style={{ color: '#333', fontSize: 14 }}>温馨提示：此课程暂不支持线上考试</div>}
                    </div>
                    <div>

                        <div style={{ position: 'relative' }}>
                            {
                                (unitDetails.type === 1 || (unitDetails.type === 3 && showPractice)) && <Spin spinning={loading} tip="您好，首次加载需要进行加密处理，请耐心等待3-5分钟左右，谢谢您的理解！"><div className='oem_details_video' onClick={videoTime}>

                                    {videoUrl && <Player style={{ width: "100%", height: '100%' }} id={`video-1`} videoId={`video-1`} ref={player}>
                                        <source src={videoUrl} />
                                        <BigPlayButton position="center" />
                                        <ControlBar>
                                            <PlaybackRateMenuButton rates={[3, 2, 1.5, 1, 0.5]} />
                                        </ControlBar>
                                    </Player>}
                                </div></Spin>
                            }
                            {
                                (unitDetails.type === 2 || (unitDetails.type === 3 && !showPractice)) && <div className='oem_details_video' style={{ cursor: 'pointer' }} onClick={() => {unitClick(unitDetails)}}>
                                    <img src={unitDetails.img} alt="" style={{ width: "100%", height: '100%' }} />
                                    {unitDetails.type === 2 && <img src={require(`../img/details11.png`)} alt="" className="unit_icon" />}
                                </div>
                            }
                        </div>
                        {
                            unitDetails.files && <div className='body_flex' style={{ marginTop: 14 }}>
                                {
                                    unitDetails.files.map((item, index) => (
                                        <div key={index} style={{ marginRight: 10 }} className="body_flex"> <img src={require('../img/details6.png')} style={{ width: 14, height: 15, marginRight: 5 }} alt='' /> <a onClick={() => { pdfClick(item) }} style={{ textDecoration: 'underline' }}>{item.name}</a> </div>
                                    ))
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className='oem_detail_right'>
                    {recommend.length > 3 && otherIndex > 3 ? <img src={require('../img/details13.png')} style={{ width: 45, height: 20, cursor: 'pointer' }} onClick={() => { imgClick(1) }} alt="" /> : <div style={{ height: 20 }}></div>}
                    <div className='oem_details_other'>
                        <div style={{ marginTop: top }}>
                            {
                                recommend.map(item => (
                                    <div className='oem_details_other_cont' style={{ background: `url(${item.img})` }} key={item.id} onClick={() => { recommendClick(item) }}>
                                        <div className='oem_details_other_footer body_flex'>
                                            <Tooltip placement="topLeft" title={item.title}><div className='text_hidden' style={{ width: 260 }}>{item.title}</div></Tooltip>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    {recommend.length > 3 && otherIndex < recommend.length && <img src={require('../img/details14.png')} style={{ width: 45, height: 20, cursor: 'pointer' }} onClick={() => { imgClick(2) }} alt="" />}
                </div>
            </div>
            <div className="details_brief">
                <div className="details_brief_title">内容简介</div>
                <div className="details_brief_cont">{unitDetails.context && unitDetails.context !== '<p></p>' ? parse(unitDetails.context) : unitDetails.intro}</div>
                {unitDetails.tagNmae && <div className="details_tag body_flex">
                    {
                        unitDetails.tagNmae.split(',').map((item, index) => (
                            <div className="details_tag_label" key={index}>{item}</div>
                        ))
                    }
                </div>}
            </div>

        </div>
    )
}
export default CourseDetails
