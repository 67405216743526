const initialState = {
  navType: -1,
  isModalOpen: false,
  token:'',
  userName: '',
  seriesKey: [-1],
  searchValue:{}
}

const reducersFunction = (state = initialState, action) => {
    switch(action.type) {
        case 'nav_type':
            return { ...state, navType: action.data };
        case 'gba_token':
            return { ...state, token: action.data };
        case 'is_modal_open':
            return { ...state, isModalOpen: action.data };
        case 'user_name':
        return { ...state, userName: action.data };
        case 'series_key':
          return { ...state, seriesKey: action.data };
        case 'search_value':
          return {...state,searchValue: action.data};
        default:
            return state;
    }
}

export default reducersFunction