import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Switch} from "react-router-dom"
import Routes from './router/index';
import reportWebVitals from './reportWebVitals';
import store from "./store";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd"
// import BackCertificate from './page/backCertificate';
import zhCN from "antd/es/locale/zh_CN"
import 'antd/dist/antd.min.css'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <ConfigProvider locale={zhCN}>
    <Provider store={store}>
<BrowserRouter>
    {/* <Router > */}
          <Switch>
          {/* <Route exact component={BackCertificate} path="/gba/backCertificate"></Route> */}
          <Routes  component={Routes} />
        </Switch>
        {/* </Router> */}
      </BrowserRouter>
    </Provider>
  </ConfigProvider>
  //   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
