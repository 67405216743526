import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Spin } from 'antd';
import domtoimage from 'dom-to-image';
import { uploadCertificate, UpdateExaminaCert , FindExaminaById} from '../api'
import moment from 'moment';
let timer
class Certificate extends React.Component {
    state = {
        visible: false,
        defaultUrl: '',
        isLoading: true,
        ifshowQuestion: true,
        endTime: ''
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    componentWillReceiveProps(nextProps) {
        nextProps.onRef && nextProps.onRef(this);
    }

    show = () => {
        this.setState({
            visible: true
        }, () => {
            const { testResult, imgUrl } = this.props;//证书展示的动态数据
            if (testResult && !this.state.defaultUrl) {
                let a = new Date(moment(testResult.submitTime).format('YYYY-MM-DD'))
                let b = new Date(a)
                b.setFullYear(b.getFullYear() + 3)
                this.setState({ endTime: moment(b).format('YYYY/MM/DD') })
                timer = setInterval(() => {
                    this.getImgUrl()
                }, 5000);

                //后台去生成证书，暂时不要了
                // if (imgUrl) {
                //     this.setState({
                //         isLoading: false,
                //         defaultUrl: imgUrl
                //     })
                // } else {
                //     setTimeout(() => {
                //         this.generateImg()
                //     }, 1000);
                // }
            }
        })
    }

    getImgUrl = () => {
        FindExaminaById({ id: this.props.testResult.id }).then(res => {
            if (res.data.statusCode === 200) {
                if (res.data.data.certificate) {
                    clearInterval(timer)
                    this.setState({
                        defaultUrl: res.data.data.certificate,
                        isLoading:false
                    })
                }
            }
        })
    }

    generateImg = () => {
        this.setState({
            isLoading: true,
        })
        const certificate = document.getElementById("certificate");
        domtoimage.toPng(certificate, { width: 759, height: 536 }).then((defaultUrl) => {
            this.setState({
                //不知道为啥.then会走两次，所以判断一下，要不图片不显示
                defaultUrl: this.state.defaultUrl ? this.state.defaultUrl : defaultUrl,
            }, () => {
                this.uploadImg()
            })

        }).catch((err) => {
            console.log(err, "error")
        })
    }

    //将base64转换为blob
    dataURLtoBlob = (dataurl) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }


    uploadImg = () => {
        if (this.state.isLoading) {
            let blob = this.dataURLtoBlob(this.state.defaultUrl)
            let file = new File([blob], new Date() + ".png")
            let params = new FormData()
            params.append('file', file)
            uploadCertificate(params).then(res => {
                UpdateExaminaCert({ id: this.props.testResult.id, certificate: res.data.url }).then(res => {
                    this.setState({
                        isLoading: false,
                    })
                }).catch(() => {
                    this.setState({
                        isLoading: false,
                    })
                })
            })
        }
    }

    getImg = () => {
        let url = this.props.imgUrl || this.state.defaultUrl
        fetch(url).then(res => res.blob()).then(blob => {
            let link = document.createElement('a');
            link.download = 'certificate.png';
            link.href = URL.createObjectURL(blob)
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            defaultUrl: '',
            isLoading: false,
        })
        if (this.props.type !== 3) {
            // window.close()
            this.props.history.go(-1)
        }
    }

    getShowQuestion = () => {
        this.setState({
            ifshowQuestion: false
        })
    }

    seeTestPaper = () => {
        this.setState({
            visible: false,
            defaultUrl: '',
            isLoading: false,
        })
        this.props.testTypeChange(this.props.testResult.id)
    }

    render() {
        return (
            <div>
                <Modal
                    title={this.props.type === 1 ? '考试通过' : this.props.type === 2 ? '考试未通过' : '证书'}
                    open={this.state.visible}
                    // closable={(this.props.type === 2 || this.state.defaultUrl) ? true : false}
                    onCancel={this.handleCancel}
                    footer={null}
                    className='certificate_modal'
                    maskClosable={false}
                    width={this.props.type !== 2 ? 800 : 477}
                >
                    {/* {console.log(this.props.testResult, 'testResult')} */}
                    {/* 考试证书 */}
                    {this.props.type && this.props.type !== 2 && <div>
                        <div className='certificate_body'>
                            <Spin spinning={this.state.isLoading} tip='证书图片生成中'>
                                {!this.state.defaultUrl ? <div className='certificate_img' id='certificate'>
                                    <div className='certificate_cont'>
                                        <div className='certificate_name'>{this.props.testResult.name}</div>
                                        <div className='certificate_course_name' >已经通过<span style={{color: '#004A86' }}>{this.props.testResult.title}</span>课程的考试</div>
                                        {/* <div className='certificate_course_name' style={{ height: 62 }}>{this.props.testResult.sname}</div> */}
                                        <div className='certificate_id'>GBAI{this.props.testResult.num_code}</div>
                                        {/* <div className='body_flex' style={{ marginTop: 42 }}> */}
                                        <div className='certificate_time'>{moment(this.props.testResult.submitTime).format('YYYY/MM/DD')}</div>
                                        <div className='certificate_time' style={{ textAlign: 'right' }}>{this.state.endTime}</div>
                                        {/* </div > */}
                                    </div>
                                </div> : <img src={this.state.defaultUrl} style={{ width: 759, height: 536 }} alt='' />}
                            </Spin>
                        </div>

                        <div className='certificate_footer body_flex'>
                            {this.props.type === 1 && <div className='test_pass'>恭喜您考试通过！您的成绩为：<span style={{ fontSize: 42, color: '#0171C5' }}>{this.props.testResult.score}</span><span style={{ color: '#0171C5' }}>分</span></div>}
                            {this.props.type === 3 && <div className='test_pass'>恭喜您考试通过！您的成绩为：<span style={{ fontSize: 42, color: '#0171C5' }}>{this.props.testResult.Num}</span><span style={{ color: '#0171C5' }}>分</span></div>}
                            <div className='body_flex'>
                                <div className='body_flex certificate_btn' onClick={this.getImg}>
                                    <img src={require("../img/certificate1.png")} style={{ width: 17, height: 17, marginRight: 4 }} alt='' />
                                    <span>下载证书</span>
                                    </div>
                            </div>
                        </div>
                    </div>}
                    {/* 考试未通过 */}
                    {
                        this.props.type === 2 && <div>
                            <div className='test_not_pass'>
                                <div className='test_not_pass_text'>抱歉您的考试不合格！您的成绩为：</div>
                                <div className='test_not_pass_sore'>{this.props.testResult.score}<span style={{ fontSize: 20 }}>分</span> </div>
                            </div>
                            <div className='test_not_pass_footer'><div className='test_not_pass_btn' onClick={this.seeTestPaper}>查看试卷</div></div>
                        </div>
                    }
                </Modal>
            </div>
        )
    }
}
export default withRouter(Certificate)