import React, { useEffect } from "react"
import {  Route,Switch } from "react-router-dom"
// import { CacheSwitch } from "react-router-cache-route" //路由
import { Layout } from "antd"
import Home from "../page/home"
import GbaHeader from "../components/header"
import CourseDetails from "../page/courseDetails"
import Test from "../page/test/test"
import News from "../page/news"
import NotFound from "../page/404"
import IPA from "../page/ipa"
import $ from 'jquery'
import '../css/common.css'
import '../css/list.css'
import '../css/oem.css'
const { Header, Content, Footer } = Layout
const BasicRoute = () => {

    return (
        <div>
            <Header>
                <GbaHeader />
            </Header>
            <div style={{ marginBottom: 20 }}>
                <Content>
                    <Switch>
                        <Route exact component={Home} path="/gba/"></Route>
                        <Route component={CourseDetails} path="/gba/coursedetails"></Route>
                        <Route component={Test} path="/gba/test"></Route>
                        <Route component={News} path="/gba/news"></Route>
                        <Route component={IPA} path="/gba/ipa"></Route>
                        <Route component={NotFound} />
                    </Switch>
                </Content>
            </div>
            <Footer>
                <div className="footers">
                    <div className="footer_cont">
                        <img src={require('../img/footer.png')} alt="" style={{ width: 329, height: 209 }} />
                        <div className="footer_right">
                            <div className="footer_right_head">
                                <a href="https://www.igbacenter.com/course/index.html" target='_blank' rel="noreferrer">人才培育</a>
                                <a href="https://www.igbacenter.com/qiyefuhua.html" target='_blank' rel="noreferrer">孵化加速</a>
                                <a href="https://www.igbacenter.com/activity/saishi.html" target='_blank' rel="noreferrer">峰会赛事</a>
                                <a href="https://www.igbacenter.com/article/zx.html" target='_blank' rel="noreferrer">中心动态</a>
                                <a href="https://www.igbacenter.com/about.html" target='_blank' rel="noreferrer">关于中心</a>
                                <a href="https://www.igbacenter.com/zhanting.html" target='_blank' rel="noreferrer">展厅预约</a>
                            </div>
                            <div className="footer_right_cont">
                                <div>
                                    {/* <a href="tel:0755-23652362" className="footer_phone">190-7615-3296</a> */}
                                    <div className="footer_news">地址：深圳市南山区西丽街道云科技大厦18-19楼</div>
                                    <div className="footer_news">邮箱：info@igbacenter.com</div>
                                </div>
                                <div className="body_flex">
                                    <div className="footer_img_back"><img src={require('../img/footer1.jpg')} alt="" style={{ width: 92, height: 92 }} /></div>
                                    <div className="footer_wechat">请扫码关注<br/>微信公众号</div>
                                </div>
                            </div>
                            <div className="footer_right_footer">
                                <span><span style={{color:'#70AAD6'}}>Copyright © 2023 英特尔大湾区科技创新中心  版权所有</span> <a href="https://beian.miit.gov.cn/" target="_blank" rel="nofollow noreferrer">粤ICP备15075380号</a></span>
                                <a href="https://www.igbacenter.com/privacy.html" target='_blank' rel="noreferrer">隐私政策</a>
                                <span>|</span>
                                <a href="https://www.igbacenter.com/agreement.html" target='_blank' rel="noreferrer">用户协议</a>
                                <span>|</span>
                                {/* <a href="https://www.igbacenter.com/sitemap.html" target='_blank' rel="noreferrer">网站地图</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Footer>
        </div>
    )
}
export default BasicRoute