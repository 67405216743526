import React, { useState, useEffect } from "react"
import { Menu } from "antd";
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { DownOutlined } from '@ant-design/icons'
import { loginOut , get_login_url} from '../api'
import LoginModal from "./loginmodal";
import Second from "./second";
const nav = [
    {
        label: '首页',
        key: '2',
    },
    {
        label: '英特尔技术与解决方案',
        key: '1',
        id: 'header_intel',
    },

]
const GbaHeader = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const userName = useSelector(state => state.common.userName)
    const [current, setCurrent] = useState('mail');
    const [items, setItems] = useState([])
    const [show, setShow] = useState(false)
    const [show1, setShow1] = useState(false)
    useEffect(() => {
        let flag = IsPC();
        if (!flag) {
            //手机端
            let url = window.location.href
            let pathname = window.location.pathname.replace(/gba/g, 'gba.m')
            if (url.indexOf('icourse') !== -1) {
                window.open(`https://icourse.igbacenter.com${pathname}`, "_self");
            } else {
                window.open(`https://cce.zqnetlink.cn${pathname}`, "_self");
            }
        }
        let a = localStorage.getItem('gbaToken')
        let arr = [...nav]
        if (a) {
            let user = JSON.parse(localStorage.getItem('gbaNews'))
            let label = <span style={{ color: '#fff' }}>{user.name}<DownOutlined /></span>
            dispatch({
                type: 'user_name',
                data: user.name
            })
            arr.push({
                label: label,
                key: '4',
                children: [
                    {
                        label: '个人信息',
                        key: '4-1',
                    },
                    {
                        label: '退出登录',
                        key: '4-2',
                    }
                ],
                icon: <img src={require("../img/icon3.png")} style={{ width: 30, height: 30 }} alt=''/>
            })
        } else {
            arr.push({
                label: '登录',
                key: '4',
                icon: <img src={require("../img/icon3.png")} style={{ width: 30, height: 30 }} alt='' />
            },
            // {
            //     label: '测试登录',
            //     key: '3',
            //     icon: <img src={require("../img/icon3.png")} style={{ width: 30, height: 30 }} alt='' />
            //     }
            )
        }
        setItems(arr)

    }, [userName])

    const IsPC = () => {
        //判断是pc端还是手机端
        let userAgentInfo = navigator.userAgent;
        let Agents = [
            "Android",
            "iPhone",
            "SymbianOS",
            "Windows Phone",
            "iPad",
            "iPod",
        ];
        let flag = true;
        for (let v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                flag = false;
                break;
            }
        }
        return flag;
    };

    useEffect(() => {
        if (items.length > 0) {
            const element = document.getElementById('header_intel');
            element.addEventListener('mouseenter', function () {
                setShow(true)
            });
            element.addEventListener('mouseleave', function () {
                setShow(false)
                setShow1(false)
            });
        }

    }, [items])

    useEffect(() => {
        if (show) {
            const element1 = document.getElementById('head_second');
            element1.addEventListener('mouseenter', function () {
                setShow(false)
                setShow1(true)
            });
            element1.addEventListener('mouseleave', function () {
                setShow(false)
                setShow1(false)
            });
        }
    }, [show])

    useEffect(() => {
        if (show || show1) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    },[show,show1])



    const menuClick = (e) => {
        setCurrent(e.key);
        if (e.key === '1') {
            history.push('/gba')
            setCurrent('');
        }else if (e.key === '3') {
            dispatch({
                type: 'is_modal_open',
                data: true
            })
        }else if (e.key === '4') {
            let url1 = window.location.href,url;
            if (url1.indexOf('icourse')!==-1) {
                url = `https://icourse.igbacenter.com/gba/`;
            } else if (url1.indexOf('zqnetlink') !== -1) {
                url = `https://cce.zqnetlink.cn/gba/`;
            }
            get_login_url({ type: 1, redirect_url:url}).then(res => {
                if (res.data.msg === 'ok') {
                    window.open(res.data.data.url,'_self')
                }
            })
        } else if (e.key === '4-2') {
            loginOut().then(res => {
                localStorage.clear()
                dispatch({
                    type: 'user_name',
                    data: ''
                })
                history.replace('/gba/')
            })
        } else if (e.key === '4-1') {
            window.open('https://www.igbacenter.com/my/index.html')
        }else if (e.key === '2') {
            window.open('https://www.igbacenter.com/course/index.html')
        }
    };

    const secondClick = () => {
        setShow(false)
        setShow1(false)
    }

    return (
        <div className="gba_head body_flex">
            <img src={require("../img/logo.png")} style={{ width: 270, height: 50,cursor:'pointer' }} alt='' onClick={()=>{window.open('https://www.igbacenter.com/')}} />
            <Menu
                // subMenuCloseDelay={1000}
                onClick={menuClick}
                selectedKeys={[current]}
                mode="horizontal"
                items={items}
                className="gba_menu"
            />
            <LoginModal />
            {(show1 || show) && <div className="head_none" id='head_second'><Second secondClick={secondClick} /></div>}
        </div>
    )
}
export default GbaHeader
