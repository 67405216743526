/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { FindExamina, SubmitExamina } from '../../api'
import Certificate from '../../components/Certificate'
import TestPaper from './testPaper'
import { Form, Button, Radio, Checkbox, Statistic } from 'antd';
const { Countdown } = Statistic;
const Test = () => {
    const location = useLocation()
    const history = useHistory()
    const params = location.state
    const [testCont, setTestCont] = useState([]);
    const [countTime, setCountTime] = useState();
    const [sort, setSort] = useState();
    const [loading, setLoading] = useState(false);
    const [checkdId, setCheckdId] = useState([]);
    const [testStartTime, setTestStartTime] = useState();
    const [testResult, setTestResult] = useState();
    const [testType, setTestType] = useState(1);
    const [testPaperId, setTestPaperId] = useState();
    const [testDetails, setTestDetails] = useState({})
    const [form] = Form.useForm()
    let certificateRef = null
    useEffect(() => {
        getTestPaper(params.id)
        getTime(1)
    }, []);

    const getTestPaper = (id) => {
        let user = JSON.parse(localStorage.getItem('gbaNews'))
        FindExamina({ tid: id, uid: user.id }).then(res => {
            if (res.data.statusCode === 200) {
                let time = res.data.data.time
                let questionList = res.data.data.questionList
                let arr = []
                questionList.map(item => {
                    arr.push(item.id)
                })
                setCountTime(Date.now() + time * 60 * 1000)
                setTestCont(questionList)
                setTestDetails(res.data.data)
                setSort(arr)
            }
        })
    }

    const getTime = (key) => {
        let date = new Date()
        let month, day, hours, minutes, seconds
        let year = date.getFullYear()
        let m = date.getMonth() + 1
        let d = date.getDate()
        let h = date.getHours()
        let f = date.getMinutes()
        let s = date.getSeconds()
        if (m < 10) {
            month = '0' + m
        } else {
            month = m
        }
        if (d < 10) {
            day = '0' + d
        } else {
            day = d
        }
        if (h < 10) {
            hours = '0' + h
        } else {
            hours = h
        }
        if (f < 10) {
            minutes = '0' + f
        } else {
            minutes = f
        }
        if (s < 10) {
            seconds = '0' + s
        } else {
            seconds = s
        }
        if (key === 1) {
            setTestStartTime(`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`)
        } else {
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
        }
    }

    const onFinish = () => {
        submitTest()
    }

    const checkClick = (id) => {
        let arr = [...checkdId]
        if (arr.includes(id)) {
            arr.splice(arr.indexOf(id), 1)
        } else {
            arr.push(id)
        }
        setCheckdId(arr)
    }

    const submitTest = () => {
        form.validateFields().then((values) => {
            setLoading(true)
            let arr = []
            for (let i in values) {
                let obj = {}
                obj.id = Number(i)
                if (typeof values[i] == 'object') {
                    obj.answer = values[i].join()
                } else {
                    obj.answer = values[i]
                }
                arr.push(obj)
            }
            let user = JSON.parse(localStorage.getItem('gbaNews'))
            let obj = {
                tid: params.id,
                uid: user.id,
                ct: testStartTime,
                submitTime: getTime(),
                questionList: arr,
                sort: sort
            }
            SubmitExamina(obj).then(res => {
                if (res.data.statusCode === 200) {
                    setLoading(false)
                    let data = res.data.data
                    data.name = user.name
                    data.title = testDetails.title
                    data.sname = testDetails.sname
                    setTestResult(data)
                }
            }).catch(() => {
                setLoading(false)
            })
        })
    }

    useEffect(() => {
        if (testResult) {
            certificateRef.show()
        }
    }, [testResult]);

    const testTypeChange = (id) => {
        setTestPaperId(id)
        setTestType(2)
    }

    useEffect(() => {
        if (testType === 1) {
            window.scroll(0, 0)
            form.resetFields()
        }
    }, [testType])

    const againTest = (id) => {
        setCheckdId([])
        setTestType(1)
        getTestPaper(id)
    }
    return (
        <div>
            {testType === 1 && <div>
                <div className="text_banner">
                    <div className="text_banner_cont body_flex">
                        <img src={require('../../img/test2.png')} alt="" style={{ width: 352, height: 212, marginRight: 37 }} />
                        <div>
                            <div className='test_text body_flex' style={{ alignItems: 'flex-start' }}>
                                <div className='body_flex'>
                                    <img src={require('../../img/test.png')} alt='' style={{ width: 26, height: 26, marginRight: 12 }} />
                                    <div style={{ width: 140 }}>单元课程名称：</div>
                                </div>
                                <div className='body_flex'><div style={{ color: '#fff', flex: 1 }}>{testDetails.title}</div></div>
                            </div>
                            <div className='test_text body_flex'>
                                <img src={require('../../img/test1.png')} alt='' style={{ width: 26, height: 26, marginRight: 12 }} />
                                <span>开始考试时间：<span style={{ color: '#fff' }}>{testStartTime}</span></span>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='test_cont'>
                    {testDetails.time && <div className='body_flex test_time'>
                        <span>倒计时：</span>
                        <Countdown title="" value={countTime} onFinish={onFinish} />
                    </div>}
                    <Form form={form}>
                        {
                            testCont.map((item, index) => {
                                if (item.question_type === 1) { //单选试题
                                    return <div className="testpaper_ha" key={item.id}>
                                        <div className="testpaper_name">{index + 1}.{item.subject}</div>
                                        <Form.Item name={`${item.id}`}>
                                            <Radio.Group>
                                                {
                                                    item.optionList.map(item1 => {
                                                        if (item1.content) {
                                                            return <Radio onClick={() => { checkClick(item1.id) }} key={item1.correct_num} value={item1.correct_num} className="test_from_1" style={{ background: checkdId.includes(item1.id) ? '#ECF3FE' : '#F9F9F9' }}>{item1.correct_num}.{item1.content}</Radio>
                                                        } else {
                                                            return ''
                                                        }
                                                    })
                                                }
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>
                                } else if (item.question_type === 2) { //多选试题
                                    return <div className="testpaper_ha" key={item.id}>
                                        <div className="testpaper_name">{index + 1}.{item.subject}</div>
                                        <Form.Item name={`${item.id}`}>
                                            <Checkbox.Group>
                                                {
                                                    item.optionList.map(item1 => {
                                                        if (item1.content) {
                                                            return <Checkbox onClick={() => { checkClick(item1.id) }} key={item1.correct_num} value={item1.correct_num} className="test_from_1" style={{ background: checkdId.includes(item1.id) ? '#ECF3FE' : '#F9F9F9' }}>{item1.correct_num}.{item1.content}</Checkbox>
                                                        } else {
                                                            return ''
                                                        }
                                                    })
                                                }
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </div>
                                } else if (item.question_type === 3) { //判断试题
                                    return <div className="testpaper_ha" key={item.id}>
                                        <div className="testpaper_name">{index + 1}.{item.subject}</div>
                                        <Form.Item name={`${item.id}`}>
                                            <Radio.Group>
                                                {
                                                    item.optionList.map((item1, index) => {
                                                        if (index <= 1) {
                                                            return <Radio onClick={() => { checkClick(item1.id) }} key={item1.correct_num} value={item1.correct_num} className="test_from_1" style={{ background: checkdId.includes(item1.id) ? '#ECF3FE' : '#F9F9F9' }}>{item1.correct_num}.{item1.correct_num === 'A' ? '正确' : '错误'}</Radio>
                                                        } else {
                                                            return ''
                                                        }
                                                    })
                                                }
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>
                                } else {
                                    return ''
                                }
                            })
                        }
                    </Form>
                    <div className='test_footer'>
                        <Button type='primary' loading={loading} onClick={submitTest}>提交试卷</Button>
                    </div>
                </div>
            </div>}
            {
                testType === 2 && <TestPaper id={testPaperId} againTest={againTest} />
            }
            <Certificate onRef={(ref) => (certificateRef = ref)} type={testResult && testResult.type} testResult={testResult} testTypeChange={testTypeChange} />
        </div>
    )
}
export default Test
