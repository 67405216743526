import React from 'react';
import { useHistory } from 'react-router-dom';
const NotFound = () => {
    const history = useHistory()
    return (
        <div className='body_flex2 body' style={{ background: '#fff' }}>
            <img src={require('../img/404.webp')} style={{ cursor: 'pointer' }} onClick={() => { history.push('/gba/') }} alt='' />
        </div>
    )
}
export default NotFound