import React, { useState, useEffect } from "react"
import { Modal, Button, Input, Form, message, Checkbox } from "antd"
import { resetPassword, updateUserInfo, loginByUsernameAndPass, loginByEmail } from '../api'
import { useSelector, useDispatch } from 'react-redux'
import Register from "./register"
import FindPass from "./findPass"
import Login from "./login"
const LoginModal = () => {
    const dispatch = useDispatch()
    const isModalOpen = useSelector(state => state.common.isModalOpen)
    const [form] = Form.useForm()
    const [state, setState] = useState(1)
    const [type, setType] = useState(1)

    const handleOk = (val) => {
        form.validateFields().then(values => {
            if (type === 3) {
                values.type = val
                resetPassword(values).then(res => {
                    if (res.data.statusCode === 200) {
                        message.success('操作成功！')
                        form.resetFields()
                        setType(1)
                    }
                })
            } else if (type === 2) {
                updateUserInfo(values).then(res => {
                    if (res.data.statusCode === 200) {
                        message.success('操作成功！')
                        form.resetFields()
                        setType(1)
                    }
                })
            } else {
                if (state === 1) {
                    //密码登录
                    loginByUsernameAndPass(values).then(res => {
                        if (res.data.statusCode === 200) {
                            message.success('操作成功！')
                            dispatch({
                                type: 'user_name',
                                data: res.data.data.name
                            })
                            localStorage.setItem('gbaToken', res.data.token)
                            localStorage.setItem('gbaNews', JSON.stringify(res.data.data))
                            form.resetFields()
                            handleCancel()
                        }
                    })
                } else {
                    //验证码登录
                    loginByEmail(values).then(res => {
                        if (res.data.statusCode === 200) {
                            dispatch({
                                type: 'user_name',
                                data: res.data.data.name
                            })
                            message.success('操作成功！')
                            localStorage.setItem('gbaToken', res.data.token)
                            localStorage.setItem('gbaNews', JSON.stringify(res.data.data))
                            form.resetFields()
                            handleCancel()
                        }
                    })
                }

            }
        })
    }

    const handleCancel = () => {
        dispatch({
            type: 'is_modal_open',
            data: false
        })
    }

    const stateChange = (val) => {
        setState(val)
    }


    const goLogin = () => {
        setType(1)
    }

    const typeChange = (val) => {
        setType(val)
    }

    const title = <img src={require('../img/loginlogo.png')} style={{ width: 314, height: 56 }} alt='' />
    return (
        <div>
            <Modal title={title}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                width={580}
            >
                <div style={{ padding: '0 70px' }}>

                    <Form form={form} layout="vertical">
                        {
                            //登录
                            type === 1 && <Login typeChange={typeChange} handleOk={handleOk} stateChange={stateChange} />
                        }
                        {
                            //注册
                            type === 2 && <Register goLogin={goLogin} handleOk={handleOk} />
                        }
                        {
                            //忘记密码
                            type === 3 && <FindPass goLogin={goLogin} handleOk={handleOk} />
                        }
                    </Form>
                </div>
            </Modal>
        </div>
    )
}
export default LoginModal
