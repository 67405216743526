import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Input, Carousel, Pagination, Tooltip, message } from "antd"
import { useDispatch , useSelector} from 'react-redux';
import { getCoursetypeList, getCourseunitList, getLearnTagList, getClassifyList , rpc_login , get_login_url} from '../api'
import { useDebounce } from '../script/common'
const Home = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const seriesKey = useSelector(state => state.common.seriesKey)
    const searchValue = useSelector(state => state.common.searchValue)
    const [seriesLabel, setSeriesLabel] = useState([])
    const [tagLabel, setTagLabel] = useState([])
    // const [seriesKey, setSeriesKey] = useState([-1])
    const [tagKey, setTagKey] = useState([-1])
    const [unitList, setUnitList] = useState([])
    const [current, setCurrent] = useState(1)
    const [total, setTotal] = useState()
    // const [searchValue, setSearchValue] = useState({})
    const [banner, setBanner] = useState([])

    useEffect(() => {

            window.scroll(0, 0)
            dispatch({
                type: 'nav_type',
                data: 5
            })
            getLearnTagList({ Publish: 1 }).then(res => {
                if (res.data.statusCode === 200) {
                    let data = res.data.data
                    data.unshift({ id: -1, name: '全部' })
                    setTagLabel(data)
                }
            })
            getCoursetypeList().then(res => {
                if (res.data.statusCode === 200) {
                    let data = res.data.data
                    data.unshift({ id: -1, name: '全部' })
                    setSeriesLabel(data)
                }
            })

            getClassifyList({state:2}).then(res => {
                if (res.data.statusCode === 200) {
                    setBanner(res.data.data)
                }
            })

            unitTableList(1)

            const { ticket } = getQueryString()
            if (ticket) {
                rpc_login({ ticket }).then(res => {
                    if (res.data.code === 200) {
                        let data = res.data.data
                        message.success('登录成功！')
                        history.replace('/gba/')
                        dispatch({
                            type: 'user_name',
                            data: data.data.name
                        })
                        let obj = {}
                        obj.id = data.data.id
                        obj.name = data.data.name
                        localStorage.setItem('gbaToken', data.token)
                        localStorage.setItem('gbaNews', JSON.stringify(obj))
                    }
                })
            }
    }, [])

    const getQueryString = (url = window.location.href) => {
        var parse = url.substring(url.indexOf("?") + 1),
            params = parse.split("&"),
            len = params.length,
            item = [],
            param = {}

        for (var i = 0; i < len; i++) {
            item = params[i].split("=")
            param[item[0]] = item[1]
        }

        return param
    }

    const unitTableList = (current, searchValue) => {
        let user = JSON.parse(localStorage.getItem('gbaNews'))
        let params = {
            skip: current,
            uid: user ? user.id : '',
            limit: 6,
            ...searchValue
        }
        getCourseunitList(params).then(res => {
            if (res.data.statusCode === 200) {
                let data = res.data.data
                data.map(item => {
                    if (item.tagNmae) {
                        item.tagNum = item.tagNmae.split(',').length
                    }
                    return item
                })
                setTotal(res.data.total)
                setUnitList(data)
            }
        })
    }

    const getKeyWord = useDebounce(obj => {
        setCurrent(1)
        unitTableList(1, obj)
    }, 1000)

    const seriesClick = (id) => {
        let arr = [...seriesKey]

        if (arr.includes(id)) {
            arr.splice(arr.indexOf(id), 1)
            if (arr.length === 0) {
                arr = [-1]
            }
        } else {
            arr.push(id)
        }
        if (arr.includes(-1) && arr.length > 1) {
            arr.splice(arr.indexOf(-1), 1)
        }

        if (id === -1) {
            arr = [-1]
        }
        dispatch({
            type: 'series_key',
            data:arr
        })
        // setSeriesKey(arr)
        let obj = { ...searchValue }
        obj.pids = arr.includes(-1) ? undefined : arr.join()
        // setSearchValue(obj)
        dispatch({
            type: 'search_value',
            data:obj
        })
        getKeyWord(obj)
    }

    useEffect(() => {
        getKeyWord(searchValue)
    },[searchValue])

    const tagClick = (id) => {
        let arr = [...tagKey]
        if (arr.includes(id)) {
            arr.splice(arr.indexOf(id), 1)
            if (arr.length === 0) {
                arr = [-1]
            }
        } else {
            arr.push(id)
        }

        if (arr.includes(-1) && arr.length > 1) {
            arr.splice(arr.indexOf(-1), 1)
        }

        if (id === -1) {
            arr = [-1]
        }
        setTagKey(arr)
        let obj = { ...searchValue }
        obj.tag_ids = arr.includes(-1) ? undefined : arr.join()
        dispatch({
            type: 'search_value',
            data:obj
        })
        // setSearchValue(obj)
        getKeyWord(obj)
    }

    const keywordChange = (e) => {
        let obj = { ...searchValue }
        obj.keyword = e.target.value
        dispatch({
            type: 'search_value',
            data:obj
        })
        // setSearchValue(obj)
        getKeyWord(obj)
    }

    const onPageChange = (current) => {
        document.querySelector('#home_cont').scrollIntoView(true)
        setCurrent(current)
        unitTableList(current, searchValue)
    }

    const learningClick = (id) => {
        let token = localStorage.getItem('gbaToken')
        if (id === 101) {
            history.push('/gba/ipa')
        } else {
            if (token) {
                history.push('/gba/coursedetails?id=' + id)
            } else {
                let url1 = window.location.href,url;
                if (url1.indexOf('icourse')!==-1) {
                    url = `https://icourse.igbacenter.com/gba/`;
                } else if (url1.indexOf('zqnetlink') !== -1) {
                    url = `https://cce.zqnetlink.cn/gba/`;
                }
                get_login_url({ type: 1, redirect_url:url}).then(res => {
                    if (res.data.msg === 'ok') {
                        window.open(res.data.data.url,'_self')
                    }
                })
            }
        }

    }

    const bannerClick = (url) => {
        if (url) {
            window.open(url)
        }
    }

    return (
        <div >
            <div className="home_banner">
                <Carousel >
                    <div className="home_banner_1">
                        <div style={{ marginTop: 112,width:1200 }}>
                            <div className="banner_title">英特尔大湾区科技创新中心 - 英特尔技术与解决方案培训</div>
                            <div className="banner_text">基于英特尔产品技术平台，携手产学研领域合作伙伴，围绕科创人才培育，打造全方位人才培养体系。课程内容聚焦于人工智能理论和产业应用、芯片软硬件应用与研发、云边端智能化、企业数字化转型以及产业创新管理等，面向个人开发者、高校学生及教师、研发人员、创业者、企事业学员及管理者，覆盖高校研究所合作、教培、企业培训等多种形式。</div>
                        </div>
                    </div>
                    {
                        banner.map(item => (
                            <div key={item.id}> <div onClick={() => { bannerClick(item.jump_url) }} className="home_banner_2" style={{ cursor: item.jump_url ? 'pointer' : 'revert'}} >
                                <div className="banner_left">
                                    <div className="body_flex">
                                        <img src={require('../img/banner1.png')} alt="" style={{ width: 97, height: 38 }} />
                                        <span className="banner_name">{item.name}</span>
                                    </div>
                                    <div className="banner_info">{item.brief}</div>
                                    <div className="body_flex banner_btn">
                                        <span>了解更多</span>
                                        <img src={require('../img/banner3.png')} alt="" style={{width:20,height:12}} />
                                    </div>
                                </div>
                                <img src={item.img} alt="" style={{width:629,height:354}} />
                            </div></div>
                        ))
                    }
                </Carousel>
            </div>
            <div className='body' id='home_cont'>
                <div className='body_flex home_search'>
                    <div className='home_label_text'>按课程系列查找：</div>
                    <div className="body_flex" style={{ flexWrap: 'wrap' }}>
                        {
                            seriesLabel.map(item => (
                                <div className={`home_label ${seriesKey.includes(item.id) ? 'home_label_active' : ''}`} key={item.id} onClick={() => { seriesClick(item.id) }}>{item.name}</div>
                            ))
                        }
                    </div>
                </div>
                <div className='body_flex home_search'>
                    <div className='home_label_text'>按课程标签查找：</div>
                    <div className="body_flex" style={{ flexWrap: 'wrap' }}>
                        {
                            tagLabel.map(item => (
                                <div className={`home_label ${tagKey.includes(item.id) ? 'home_label_active' : ''}`} key={item.id} onClick={() => { tagClick(item.id) }}>{item.name}</div>
                            ))
                        }
                    </div>
                </div>
                <div className='body_flex home_search'>
                    <div className='home_label_text' style={{ marginTop: 5 }}>关键字查找：</div>
                    <div style={{ position: 'relative' }}>
                        <Input placeholder="请输入您感兴趣的内容" style={{ width: 347 }} onChange={(e) => { keywordChange(e) }} allowClear />
                       {!searchValue.keyword && <img src={require('../img/search.png')} className="home_search_icon" alt="" />}
                    </div>
                </div>

                <div className="home_cont body_flex">
                    {
                        unitList.length > 0 ? unitList.map(item => (
                            <div key={item.id} className="home_unit_card" onClick={() => { learningClick(item.id) }}>
                                <div style={{ position: 'relative', width: 356 }}>
                                    <div className="home_unit_tag body_flex">
                                        {
                                            item.hot === 1 && <div className="home_unit_tag_label" style={{ background: '#57CD4A' }}>热门课程</div>
                                        }

                                        {item.tagNmae && <div className="home_unit_tag_label">
                                            {
                                                item.tagNmae.split(',').map((item1, index) => (
                                                    <span key={index}><span>{item1}</span> {index + 1 !== item.tagNum && <span className="home_tag_line"></span>}</span>
                                                ))
                                            }
                                        </div>}
                                    </div>
                                    <div className="home_unit_info "><div className="text_hidden2">{item.intro}</div></div>
                                    <img src={item.img} style={{ width: 356, height: 200 }} alt='' />
                                </div>
                                <Tooltip placement="topLeft" title={item.title}><div className="home_unit_title text_hidden">{item.title}</div></Tooltip>
                                <div className="body_flex home_unit_text">{item.teacher && <span>课程讲师：{item.teacher}</span>} {item.teacher && <div className="home_line"></div>}课程时长：{item.duration}分钟</div>
                                <div className="body_flex home_btn_div">
                                    {item.examuid === 2 && <div className="home_btn home_no_pass">未通过</div>}
                                    {item.examuid === 1 && <div className="home_btn home_pass">已通过</div>}
                                    <div className="home_learn_btn" >立即学习</div>
                                </div>
                            </div>
                        )) : <div className="home_nothing">
                            <img src={require('../img/nothing.png')} alt="" />
                            <div>暂无数据</div>
                        </div>
                    }
                </div>

                {unitList.length > 0 && <div className='home_card_page' >
                    <Pagination current={current} total={total} pageSize={6} onChange={onPageChange} />
                </div>}
            </div>
        </div>
    )
}
export default Home
