import axios from 'axios'
import qs from 'qs'
import { message } from 'antd'
import history from '../history'
import store from '../store'
// const store = createStore(counterReducer);
axios.defaults.withCredentials = true
const service = axios.create({
  baseURL: '', // api的base_url
  withCredentials: true,
  headers: {},
  data: qs.stringify(function (data) {
    return data
  }),
});

service.interceptors.request.use(config => {
  config.headers.authorization = localStorage.getItem('gbaToken')
  config.headers.accessToken = localStorage.getItem('gbaToken')
  return config

})
service.interceptors.response.use(
  response => response,
  error => {
    if (error.response.data.error.statusCode === 503 || error.response.data.error.message === '登录已过期') {
      localStorage.clear()
      store.dispatch({
        type: 'user_name',
        data: ''
      })
      history.push('/gba')
      window.location.reload()
    } else {
      message.error(error.response.data.error.message)
    }

    return Promise.reject(error)
  }
);
export default service