import React , { useEffect, useState , useImperativeHandle , forwardRef}from "react"
import { Button, message} from "antd"
import { getCodeByMobile , getCodeByEmail , sendEmailCode} from '../api'
const GetCodeBtn = (props,ref) => {
    useImperativeHandle(ref, () => ({ // 暴露给父组件的方法
        getInfo:resetBtn
    }))
    const [phone,setPhone] = useState()
    const [phoneFlag, setPhoneFlag] = useState(0)
    const [time, setTime] = useState(60)
    const phoneTextArr = ["获取验证码", time + "s后获取"]
    let times

    const sendCode = () => {
        if (props.phone) {
            codeFuntion()
        } else {
            message.warning(props.message)
        }
    }

    const codeFuntion = () => {
        if (props.ifPas) {
            //忘记密码发送
            getCodeByEmail({email:props.phone}).then((res) => {
                if (res.data.statusCode == 200) {
                    setPhoneFlag(1)
                    setTime(60)
                    message.success("验证码发送成功！")
                } else {
                    setPhoneFlag(0)
                    setTime(60)
                }
            }).catch(err => {
                resetBtn()
                clearTimeout(times)
            })
        } else {
            //注册、验证码登录发送
            getCodeByMobile({ username: props.phone, type:props.type}).then((res) => {
                if (res.data.statusCode == 200) {
                    setPhoneFlag(1)
                    setTime(60)
                    message.success("验证码发送成功！")
                } else {
                    setPhoneFlag(0)
                    setTime(60)
                }
            }).catch(err => {
                resetBtn()
                clearTimeout(times)
            })
        }
    }

    useEffect(() => {
        if (!!phoneFlag) {
            let times = setTimeout(() => {
                setTime((time) => time - 1)
                if (time <= 0) {
                    resetBtn()
                    return clearTimeout(times)
                }
            }, 1000)
        }
    }, [time, phoneFlag])

    const resetBtn = () => {
        setPhoneFlag(0)
        setTime(60)
    }
    return (
         <Button type='primary' className="login_getcode_btn" disabled={!!phoneFlag} onClick={() => { sendCode(4) }}>{phoneTextArr[phoneFlag]}</Button>
    )
}
export default forwardRef(GetCodeBtn)
