import React, { useEffect, useState } from "react"
import { FindCoursetype , get_login_url} from '../api/'
import { useHistory } from "react-router-dom"
import { useDispatch , useSelector} from 'react-redux';
import { Tooltip } from "antd"
const Second = (props) => {
    const dispatch = useDispatch()
    const seriesKey = useSelector(state => state.common.seriesKey)
    const searchValue = useSelector(state => state.common.searchValue)
    const history = useHistory()
    const [nav, setNav] = useState([])

    useEffect(() => {
        FindCoursetype().then(res => {
            if (res.data.statusCode === 200) {
                let data = res.data.data
                // let obj = {
                //     id: -2,
                //     name: '英特尔合作伙伴能力课程系列',
                //     child: [
                //         {
                //             title: '查看详情',
                //             id: -1
                //         }
                //     ]
                // }
                // data.push(obj)
                setNav(data)
            }
        })
    }, [])

    const navClick = (id) => {
        let url = window.location.href
        let token = localStorage.getItem('gbaToken')
        if (token || id === 101) {
            if (id !== 101) {
                if (url.indexOf('coursedetails') !== -1) {
                    history.replace('/gba/coursedetails?id=' + id)
                    window.location.reload()
                } else {
                    history.push('/gba/coursedetails?id=' + id)
                }
            } else {
                history.push('/gba/ipa')
            }
            props.secondClick()
        } else {
            let url1 = window.location.href,url;
            if (url1.indexOf('icourse')!==-1) {
                url = `https://icourse.igbacenter.com/gba/`;
            } else if (url1.indexOf('zqnetlink') !== -1) {
                url = `https://cce.zqnetlink.cn/gba/`;
            }
            get_login_url({ type: 1, redirect_url:url}).then(res => {
                if (res.data.msg === 'ok') {
                    window.open(res.data.data.url,'_self')
                }
            })
        }
    }

    const navClick1 = (id) => {
        let arr = [...seriesKey]

        if (arr.includes(id)) {
            arr.splice(arr.indexOf(id), 1)
            if (arr.length === 0) {
                arr = [-1]
            }
        } else {
            arr.push(id)
        }
        if (arr.includes(-1) && arr.length > 1) {
            arr.splice(arr.indexOf(-1), 1)
        }

        if (id === -1) {
            arr = [-1]
        }
        dispatch({
            type: 'series_key',
            data:arr
        })
        let obj = { ...searchValue }
        obj.pids = arr.includes(-1) ? undefined : arr.join()
        dispatch({
            type: 'search_value',
            data:obj
        })
        props.secondClick()
    }
    return (
        <div className="second_body">
            <div className="second_hidden" >
                <div className="body_flex second_cont">
                    {
                        nav.map(item => (
                            <div key={item.id} className="second_title">
                                <Tooltip placement="topLeft" title={item.name}><div className="second_title_color text_hidden" onClick={() => { navClick1(item.id) }}>{item.name}</div></Tooltip>
                                <div>
                                    {
                                        item.child && item.child.map(item1 => (
                                            <div className="second_child_title" key={item1.id} onClick={() => { navClick(item1.id) }}>{item1.title}</div>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
export default Second
