import React, {useState , useEffect, useRef} from "react"
import { Modal, Button, Input, Form, message, Checkbox } from "antd"
import GetCodeBtn from "./getCode"
const Register = (props) => {
    const getCodeBtnRef= useRef()
    const [checked, setChecked] = useState(false)
    const [phone,setPhone] = useState()

    const raidoChange = (e) => {
        setChecked(e.target.checked)
    }
    

   
    return (
        <div>
            <div className="login_register">
                <Form.Item
                    label="邮箱："
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: '请输入邮箱！',
                        },
                    ]}
                >
                    <Input placeholder="请输入您的邮箱" />
                </Form.Item>
                <Form.Item
                    label="姓名："
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: '请输入姓名！',
                        },
                    ]}
                >
                    <Input placeholder="请输入您的姓名" />
                </Form.Item>
                <Form.Item
                    label="手机号："
                    name="phone"
                    rules={[
                        {
                            required: true,
                            message: '请输入手机号！',
                        },
                    ]}
                >
                    <Input placeholder="请输入您的手机号" onChange={(e) => { setPhone(e.target.value) }} />
                </Form.Item>
                <Form.Item label='验证码：' className="login_code_item">
                    <div className="body_flex">
                        <Form.Item
                            noStyle
                            name="code"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入验证码！',
                                },
                            ]}
                        >
                            <Input placeholder="请输入手机号验证码" />
                        </Form.Item>
                        <GetCodeBtn ref={getCodeBtnRef} phone={phone} message='请输入手机号' type={3} />
                    </div>
                </Form.Item>
                <Form.Item
                    label="登录密码："
                    name="ming_pass"
                    rules={[
                        {
                            required: true,
                            message: '请输入密码！',
                        },
                    ]}
                >
                    <Input placeholder="请输入密码" />
                </Form.Item>
                <div className="login_clause"><Checkbox onChange={raidoChange} checked={checked}>同意 <a style={{ color: '#0171C5' }}>《英特尔隐私保护条款》</a></Checkbox></div>
                <div className="login_clause_text">注<strong style={{ color: '#F12424' }}>*</strong> 为必填信息请认真填写
                    若提交此表单，代表您确认同意将个人信息与英特尔共享，以用于业务需求。同时，也代表您同意英特尔通过电子邮件和电话向您发送最新技术和行业趋势资讯。您可以随时退订。英特尔的网站和信息受
                    <a
                        style={{ color: '#0171C5' }}
                        target="_blank"
                        href="https://www.intel.cn/content/www/cn/zh/privacy/intel-privacy-notice.html"
                    >
                        隐私声明
                    </a>
                    和
                    <a
                        style={{ color: '#0171C5' }}
                        target="_blank"
                        href="https://www.intel.cn/content/www/cn/zh/legal/terms-of-use.html"
                    >
                        使用条款
                    </a>
                    的约束
                </div>
                <Button type='primary' className="login_btn" onClick={()=>{props.handleOk()}}>提交信息</Button>
                <div className="login_register_text"><a onClick={() => { props.goLogin() }}>已有账号？立即登录</a></div>
            </div>
        </div>
    )
}
export default Register
