import React , {useState , useEffect, useRef} from "react"
import { Modal, Button, Input, Form, message , Checkbox} from "antd"
import GetCodeBtn from './getCode'
const FindPass = (props) => {
    const getCodeBtnRef=useRef()
    const [pasType, setPasType] = useState(3)
    const [phone, setPhone] = useState()
    

    return (
        <div style={{marginBottom:20}}>
            {
                pasType == 3 && <div style={{ width: 490, marginLeft: -46 }}>
                    <div>
                        <div className="body_flex">
                            <div className="login_pas_find" style={{ marginRight: 30 }} onClick={() => { setPasType(2) }}>
                                <img src={require('../img/pas_email.png')} style={{ width: 90, height: 90 }} />
                                <div className="login_pas_text">通过邮箱找回</div>
                                <img src={require('../img/icon4.png')} style={{ width: 29, height: 29 }} />
                            </div>
                            <div className="login_pas_find" onClick={() => {setPasType(1) }}>
                                <img src={require('../img/pas_phone.png')} style={{ width: 90, height: 90 }} />
                                <div className="login_pas_text">通过手机号找回</div>
                                <img src={require('../img/icon4.png')} style={{ width: 29, height: 29 }} />
                            </div>
                        </div>
                        <div style={{ textAlign: 'center', marginTop: 28 }}><a onClick={() => { props.goLogin() }} className="go_login">返回登录</a></div>
                    </div>
                </div>}
            {
                (pasType ==1 || pasType==2) && <div>
                    <Form.Item
                        label={`${pasType == 2 ? '邮箱' :'手机号'}：`}
                        name="phone"
                        rules={[
                            {
                                required: true,
                                message: `请输入您的注册${pasType == 2 ? '邮箱' :'手机号'}！`,
                            },
                        ]}
                    >
                        <Input placeholder={`请输入您的注册${pasType == 2 ? '邮箱' :'手机号'}`} onChange={(e) => { setPhone(e.target.value) }} />
                    </Form.Item>
                    <Form.Item label='验证码：' className="login_code_item">
                        <div className="body_flex">
                            <Form.Item
                                noStyle
                                name="code"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入验证码！',
                                    },
                                ]}
                            >
                                <Input placeholder="请输入验证码" />
                            </Form.Item>
                            <GetCodeBtn ref={getCodeBtnRef} phone={phone} message={pasType == 2 ? '请输入邮箱' : '请输入手机号'} type={4} ifPas={true} />
                        </div>
                    </Form.Item>
                    <Form.Item
                        label='新密码：'
                        name="password"
                        rules={[{ required: true, message: '请输入新密码' }]}
                    >
                        <Input.Password placeholder="请输入新密码"/>
                    </Form.Item>
                    <Form.Item
                        label='确认新密码：'
                        name="password1"
                        rules={[
                            { required: true, message: '请输入新密码' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('两次密码输入不一致！'));
                                },
                                })
                        ]}
                    >
                        <Input.Password placeholder="请输入您的确认密码"/>
                    </Form.Item>
                    <Button type='primary' className="login_btn" onClick={()=>{props.handleOk(pasType)}}>提交信息</Button>
                </div>
            }
        </div>
    )
}
export default FindPass
