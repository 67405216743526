import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Button, Radio, Checkbox } from 'antd';
import { FindExaminaById } from '../../api'
import moment from 'moment';
class TestPaper extends React.Component {
    state = {
        id: '',
        testCont: [],
        testDetails: {},
        checkdId: [],
        btndisabled: false
    }
    componentDidMount() {
        window.scroll(0, 0)
        if (this.props.id) {
            this.setState({ id: this.props.id })
            FindExaminaById({ id: this.props.id }).then(res => {
                if (res.data.statusCode == 200) {
                    let data = res.data.data
                    let arr = []
                    data.questionList.map(item => {
                        item.optionList.map(item1 => {
                            if (item.answer && item.answer.includes(item1.correct_num)) {
                                arr.push(item1.id)
                            }
                        })
                    })
                    this.setState({
                        testDetails: data,
                        testCont: data.questionList,
                        checkdId: arr,
                        btndisabled: data.use_exam > 0 ? false : true
                    })
                }
            })
        }
    }

    render() {
        return (
            <div style={{ background: '#F8FBFF', paddingBottom: 40 }}>
                <div className='text_banner' style={{ justifyContent: 'flex-start' }}>
                    <div className='body_flex' style={{ width: 1200, paddingLeft: 99 }}>
                        <img src={require("../../img/text2.png")} alt='' style={{ width: 352, height: 212, marginRight: 37 }} />
                        <div>
                            <div className='test_text body_flex'>
                                <img src={require("../../img/text.png")} alt='' style={{ width: 26, height: 26, marginRight: 12 }} />
                                <span>单元课程名称：<span style={{ color: '#fff' }}>{this.state.testDetails.title}</span></span>
                            </div>
                            <div className='test_text body_flex'>
                                <img src={require("../../img/text1.png")} alt='' style={{ width: 26, height: 26, marginRight: 12 }} />
                                <span>考试时间：<span style={{ color: '#fff' }}>{moment(this.state.testDetails.ct).format('YYYY-MM-DD HH:mm')}</span></span>
                            </div>
                            <div className='test_text body_flex'>
                                <img src={require("../../img/text1.png")} alt='' style={{ width: 26, height: 26, marginRight: 12 }} />
                                <span>交卷时间：<span style={{ color: '#fff' }}>{moment(this.state.testDetails.submitTime).format('YYYY-MM-DD HH:mm')}</span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='test_body'>
                    <div className='test_cont'>
                        <div className='body_flex test_time'>
                            <span>得分：</span>
                            <span style={{ color: '#0071C4', fontSize: 30 }}>{this.state.testDetails.score}</span>
                        </div>
                        <Form >
                            {
                                this.state.testCont.map((item, index) => {
                                    if (item.question_type === 1) { //单选试题
                                        return <div className="testpaper_ha" key={item.id}>
                                            <div className='body_flex' style={{ justifyContent: 'space-between' }}>
                                                <div className="testpaper_name">{index + 1}.{item.subject}</div>
                                                {item.accuracy === 0 && <div className='testpaper_err'>该题回答错误</div>}
                                            </div>
                                            <Form.Item name={`${item.id}`} initialValue={item.answer}>
                                                <Radio.Group disabled>
                                                    {
                                                        item.optionList.map(item1 => {
                                                            if (item1.content) {
                                                                return <Radio onClick={() => { this.checkClick(item1.id) }} key={item1.correct_num} value={item1.correct_num} className="test_from_1" style={{ background: this.state.checkdId.includes(item1.id) ? '#ECF3FE' : '#F9F9F9' }}>{item1.correct_num}.{item1.content}</Radio>
                                                            }

                                                        })
                                                    }
                                                </Radio.Group>
                                            </Form.Item>
                                        </div>
                                    }
                                    if (item.question_type === 2) { //多选试题
                                        return <div className="testpaper_ha" key={item.id}>
                                            <div className='body_flex' style={{ justifyContent: 'space-between' }}>
                                                <div className="testpaper_name">{index + 1}.{item.subject}</div>
                                                {item.accuracy === 0 && <div className='testpaper_err'>该题回答错误</div>}
                                            </div>
                                            <Form.Item name={`${item.id}`} initialValue={item.answer ? item.answer.split(',') : []}>
                                                <Checkbox.Group disabled>
                                                    {
                                                        item.optionList.map(item1 => {
                                                            if (item1.content) {
                                                                return <Checkbox onClick={() => { this.checkClick(item1.id) }} key={item1.correct_num} value={item1.correct_num} className="test_from_1" style={{ background: this.state.checkdId.includes(item1.id) ? '#ECF3FE' : '#F9F9F9' }}>{item1.correct_num}.{item1.content}</Checkbox>
                                                            }
                                                        })
                                                    }
                                                </Checkbox.Group>
                                            </Form.Item>
                                        </div>
                                    }
                                    if (item.question_type === 3) { //判断试题
                                        return <div className="testpaper_ha" key={item.id}>
                                            <div className='body_flex' style={{ justifyContent: 'space-between' }}>
                                                <div className="testpaper_name">{index + 1}.{item.subject}</div>
                                                {item.accuracy === 0 && <div className='testpaper_err'>该题回答错误</div>}
                                            </div>
                                            <Form.Item name={`${item.id}`} initialValue={item.answer}>
                                                <Radio.Group disabled>
                                                    {
                                                        item.optionList.map((item1, index) => {
                                                            if (index <= 1) {
                                                                return <Radio onClick={() => { this.checkClick(item1.id) }} key={item1.correct_num} value={item1.correct_num} className="test_from_1" style={{ background: this.state.checkdId.includes(item1.id) ? '#ECF3FE' : '#F9F9F9' }}>{item1.correct_num}.{item1.correct_num == 'A' ? '正确' : '错误'}</Radio>
                                                            }
                                                        })
                                                    }
                                                </Radio.Group>
                                            </Form.Item>
                                        </div>
                                    }
                                })
                            }
                        </Form>
                    </div>
                    <div className='test_footer'>
                        <Button type='primary' disabled={this.state.btndisabled} onClick={() => { this.props.againTest(this.state.testDetails.tid) }}>重新考试</Button>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(TestPaper);