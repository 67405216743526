import React from 'react';
import ipa1 from '../img/ipa1.png'
import ipa2 from '../img/ipa2.png'
import ipa3 from '../img/ipa3.png'
import ipa4 from '../img/ipa4.png'
import ipa5 from '../img/ipa5.png'
import ipa6 from '../img/ipa6.png'
const IPA = () => {
    const cont = [
        {
            title: '客户端计算',
            text: '浏览业务和消费级解决方案能力课程主题，帮助您的客户更智能、更高效地工作并保持互连。',
            img: ipa1,
            url:'https://partneruniversity.intel.com/pages/52/competencies-client-computing'
        },
        {
            title: '数据中心',
            text: '浏览围绕当今数字化世界的业务解决方案能力课程。',
            img: ipa2,
            url:'https://partneruniversity.intel.com/pages/68/competencies-data-center-and-cloud'
        },
        {
            title: '物联网',
            text: '浏览物联网(IOT) 能力课程。在物联网领域做出明智决策，打入不断增长的物联网市场。',
            img:ipa3,
            url:'https://partneruniversity.intel.com/pages/69/competencies-iot'
        },
        {
            title: '存储和内存',
            text: '浏览面向数据中心的英特尔® 傲腾™技术能力课程，帮助满足不同工作负载的存储需求。',
            img:ipa4,
            url:'https://partneruniversity.intel.com/pages/70/competencies-storage-and-memory'
        },
        {
            title: 'FPGA',
            text: '浏览 FPGA 能力课程，了解如何借机扩大市场份额。',
            img:ipa5,
            url:'https://partneruniversity.intel.com/pages/71/competencies-fpga'
        },
        {
            title: '专业技能',
            text: '浏览各类主题，用成熟的方法论和最佳实践来打造您的专业销售技能。',
            img:ipa6,
            url:'https://partneruniversity.intel.com/pages/100/competencies-professional-skills'
        },
    ]
    return (
        <div>
            <div className='ipa_cont'>
                <div className='body_flex'>
                    <div className='ipa_ba'>
                        <div className='ipa_cont_title'>英特尔合作伙伴联盟是什么？</div>
                        <div className='ipa_cont_text'>英特尔合作伙伴联盟(IPA)是英特尔面向全球的合作伙伴推出的一项致力于整合生态资源，创建业界顶级生态链接，提供丰富的权益为高绩效合作伙伴提供奖励，升级等促进合作伙伴业务成长的计划。同时为合作伙伴提供上下游产业资源对接、创新项目协作、专业培训指导、市场营销推广等多方面支持,为联盟的合作伙伴全面增值提供助力。 </div>
                        <div className='body_flex' style={{marginTop:35}} onClick={()=>{window.open('https://www.intel.com/content/www/cn/zh/secure/forms/ipa-self-registration.html')}}>
                            <div className='ipa_cont_btn' onClick={()=>{window.open('https://partnersocial.intel.cn/partner-alliance-registration?utm_source=QRcode')}}>立即加入</div>
                            <div className='ipa_cont_register' onClick={()=>{window.open('https://video.apicloud-intel.com/o_1h310l3ub1ocro391jf61llpnd88.pdf')}}>注册操作手册</div>
                        </div>
                    </div>
                    <div className='ipa_right'>
                        {/* <img src={require('../img/ipa_play.png')} alt="" style={{width:92,height:92}} /> */}
                    </div>
                </div>
                <div className='ipa_text'>注册后您可以获得哪些能力课程认证的学习？</div>
                <div className='body_flex ipa_card_cont'>
                    {
                        cont.map((item, index) => (
                            <div className='ipa_card' key={index} style={{ backgroundImage: `url(${item.img})`}} onClick={()=>{window.open(item.url)}}>
                                <div className='ipa_card_title'>{item.title}</div>
                                <div className='ipa_card_text'>{item.text}</div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
export default IPA